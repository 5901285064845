require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0ed25ecc6faed036892dd73ab6918ddd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd'] = async function fn_0ed25ecc6faed036892dd73ab6918ddd() {
  await (async function() {
  	document.documentElement.setAttribute("lang", 'lv');

  })();

}

})
define('./functions/a2695a499b038ccdb4fca1e8ba0b3e4f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'] = function fn_a2695a499b038ccdb4fca1e8ba0b3e4f(team) {
var teamId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  teamId = (getObjectProperty(team, 'teamId')).charAt(0);
  if (teamId == 'T') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('controlpoint', undefined);
  } else if (teamId == 'S') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('start', undefined);
  } else if (teamId == 'F') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('finish', undefined);
  } else if (teamId == 'A') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin', undefined);
  } else if (teamId == 'P') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('add', undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('participant', undefined);
  }

}

})
define('./functions/bc01a48af416ec799ecfaa9af64b0313/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bc01a48af416ec799ecfaa9af64b0313'] = function fn_bc01a48af416ec799ecfaa9af64b0313(points) {
var pointsTotal, cp8Total, cp8Sport, cp8Quiz, cp8Arival, cp7Total, cp7Sport, cp7Quiz, cp7Arival, cp6Total, cp6Sport, cp6Quiz, cp6Arival, cp5Total, cp5Soprt, cp5Quiz, cp5Arival, cp4Total, cp4Sport, cp4Quiz, cp4Arival, cp3Total, cp3Sport, cp3Quiz, cp3Arival, cp2Total, cp2Sport, cp2Quiz, cp2Arival, cp1Total, cp1Sport, cp1Quiz, cp1Arival, points, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cp1Arival = (getObjectProperty(points, 'cp1Arival'));
  if (cp1Arival == null) {
    cp1Arival = 0;
  }
  cp1Quiz = (getObjectProperty(points, 'cp1Quiz'));
  if (cp1Quiz == null) {
    cp1Quiz = 0;
  }
  cp1Sport = (getObjectProperty(points, 'cp1Sport'));
  if (cp1Sport == null) {
    cp1Sport = 0;
  }
  cp1Total = cp1Arival + cp1Quiz + cp1Sport;
  cp2Arival = (getObjectProperty(points, 'cp2Arival'));
  if (cp2Arival == null) {
    cp2Arival = 0;
  }
  cp2Quiz = (getObjectProperty(points, 'cp2Quiz'));
  if (cp2Quiz == null) {
    cp2Quiz = 0;
  }
  cp2Sport = (getObjectProperty(points, 'cp2Sport'));
  if (cp2Sport == null) {
    cp2Sport = 0;
  }
  cp2Total = cp2Arival + cp2Quiz + cp2Sport;
  cp3Arival = (getObjectProperty(points, 'cp3Arival'));
  if (cp3Arival == null) {
    cp3Arival = 0;
  }
  cp3Quiz = (getObjectProperty(points, 'cp3Quiz'));
  if (cp3Quiz == null) {
    cp3Quiz = 0;
  }
  cp3Sport = (getObjectProperty(points, 'cp3Sport'));
  if (cp3Sport == null) {
    cp3Sport = 0;
  }
  cp3Total = cp3Arival + cp3Quiz + cp3Sport;
  cp4Arival = (getObjectProperty(points, 'cp4Arival'));
  if (cp4Arival == null) {
    cp4Arival = 0;
  }
  cp4Quiz = (getObjectProperty(points, 'cp4Quiz'));
  if (cp4Quiz == null) {
    cp4Quiz = 0;
  }
  cp4Sport = (getObjectProperty(points, 'cp4Sport'));
  if (cp4Sport == null) {
    cp4Sport = 0;
  }
  cp4Total = cp4Arival + cp4Quiz + cp4Sport;
  cp5Arival = (getObjectProperty(points, 'cp5Arival'));
  if (cp5Arival == null) {
    cp5Arival = 0;
  }
  cp5Quiz = (getObjectProperty(points, 'cp5Quiz'));
  if (cp5Quiz == null) {
    cp5Quiz = 0;
  }
  cp5Soprt = (getObjectProperty(points, 'cp5Sport'));
  if (cp5Soprt == null) {
    cp5Soprt = 0;
  }
  cp5Total = cp5Arival + cp5Quiz + cp5Soprt;
  cp6Arival = (getObjectProperty(points, 'cp6Arival'));
  if (cp6Arival == null) {
    cp6Arival = 0;
  }
  cp6Quiz = (getObjectProperty(points, 'cp6Quiz'));
  if (cp6Quiz == null) {
    cp6Quiz = 0;
  }
  cp6Sport = (getObjectProperty(points, 'cp6Sport'));
  if (cp6Sport == null) {
    cp6Sport = 0;
  }
  cp6Total = cp6Arival + cp6Quiz + cp6Sport;
  cp7Arival = (getObjectProperty(points, 'cp7Arival'));
  if (cp7Arival == null) {
    cp7Arival = 0;
  }
  cp7Quiz = (getObjectProperty(points, 'cp7Quiz'));
  if (cp7Quiz == null) {
    cp7Quiz = 0;
  }
  cp7Sport = (getObjectProperty(points, 'cp7Sport'));
  if (cp7Sport == null) {
    cp7Sport = 0;
  }
  cp7Total = cp7Arival + cp7Quiz + cp7Sport;
  cp8Arival = (getObjectProperty(points, 'cp8Arival'));
  if (cp8Arival == null) {
    cp8Arival = 0;
  }
  cp8Quiz = (getObjectProperty(points, 'cp8Quiz'));
  if (cp8Quiz == null) {
    cp8Quiz = 0;
  }
  cp8Sport = (getObjectProperty(points, 'cp8Sport'));
  if (cp8Sport == null) {
    cp8Sport = 0;
  }
  cp8Total = cp8Arival + cp8Quiz + cp8Sport;
  pointsTotal = cp1Total + cp2Total + cp3Total + cp4Total + cp5Total + cp6Total + cp7Total + cp8Total;
  pointsTotal = String(pointsTotal);
  if (pointsTotal.length > 6) {
    pointsTotal = pointsTotal.slice(0, 6);
  }
  cp1Sport = String(cp1Sport);
  if (cp1Sport.length > 5) {
    cp1Sport = cp1Sport.slice(0, 5);
  }
  cp1Total = String(cp1Total);
  if (cp1Total.length > 5) {
    cp1Total = cp1Total.slice(0, 5);
  }
  cp2Sport = String(cp2Sport);
  if (cp2Sport.length > 5) {
    cp2Sport = cp2Sport.slice(0, 5);
  }
  cp2Total = String(cp2Total);
  if (cp2Total.length > 5) {
    cp2Total = cp2Total.slice(0, 5);
  }
  cp3Sport = String(cp3Sport);
  if (cp3Sport.length > 5) {
    cp3Sport = cp3Sport.slice(0, 5);
  }
  cp3Total = String(cp3Total);
  if (cp3Total.length > 5) {
    cp3Total = cp3Total.slice(0, 5);
  }
  cp4Sport = String(cp4Sport);
  if (cp4Sport.length > 5) {
    cp4Sport = cp4Sport.slice(0, 5);
  }
  cp4Total = String(cp4Total);
  if (cp4Total.length > 5) {
    cp4Total = cp4Total.slice(0, 5);
  }
  cp5Soprt = String(cp5Soprt);
  if (cp5Soprt.length > 5) {
    cp5Soprt = cp5Soprt.slice(0, 5);
  }
  cp5Total = String(cp5Total);
  if (cp5Total.length > 5) {
    cp5Total = cp5Total.slice(0, 5);
  }
  cp6Sport = String(cp6Sport);
  if (cp6Sport.length > 5) {
    cp6Sport = cp6Sport.slice(0, 5);
  }
  cp6Total = String(cp6Total);
  if (cp6Total.length > 5) {
    cp6Total = cp6Total.slice(0, 5);
  }
  cp7Sport = String(cp7Sport);
  if (cp7Sport.length > 5) {
    cp7Sport = cp7Sport.slice(0, 5);
  }
  cp7Total = String(cp7Total);
  if (cp7Total.length > 5) {
    cp7Total = cp7Total.slice(0, 5);
  }
  cp8Sport = String(cp8Sport);
  if (cp8Sport.length > 5) {
    cp8Sport = cp8Sport.slice(0, 5);
  }
  cp8Total = String(cp8Total);
  if (cp8Total.length > 5) {
    cp8Total = cp8Total.slice(0, 5);
  }

return ({ 'cp1Arival': cp1Arival,'cp1Quiz': cp1Quiz,'cp1Sport': cp1Sport,'cp1Total': cp1Total,'cp2Arival': cp2Arival,'cp2Quiz': cp2Quiz,'cp2Sport': cp2Sport,'cp2Total': cp2Total,'cp3Arival': cp3Arival,'cp3Quiz': cp3Quiz,'cp3Sport': cp3Sport,'cp3Total': cp3Total,'cp4Arival': cp4Arival,'cp4Quiz': cp4Quiz,'cp4Sport': cp4Sport,'cp4Total': cp4Total,'cp5Arival': cp5Arival,'cp5Quiz': cp5Quiz,'cp5Sport': cp5Soprt,'cp5Total': cp5Total,'cp6Arival': cp6Arival,'cp6Quiz': cp6Quiz,'cp6Sport': cp6Sport,'cp6Total': cp6Total,'cp7Arival': cp7Arival,'cp7Quiz': cp7Quiz,'cp7Sport': cp7Sport,'cp7Total': cp7Total,'cp8Arival': cp8Arival,'cp8Quiz': cp8Quiz,'cp8Sport': cp8Sport,'cp8Total': cp8Total,'pointsTotal': pointsTotal })
}

})
define('./functions/f80223fbfb1deafaeefad6939f65270e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e'] = async function fn_f80223fbfb1deafaeefad6939f65270e() {
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

}

})
define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await ( async function (pageName, pageData) { BackendlessUI.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/add/components/318ec8abfef3e34cdd6fdc958fb3b239/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, teamName, teamId, password, participant5age, participant5, paticipant4age, participant4, participant3age, participant3, participant2age, participant2, routeId, group, category, captainAge, teamCaptain, phone, email, newTeam, lastRoute, lastTeamId, lastTeam, lastGroup, lastCategory, togetherWith;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  try {
    teamName = (getObjectProperty(___arguments.context.dataModel, 'teamName'));
    email = (getObjectProperty(___arguments.context.dataModel, 'email'));
    phone = (getObjectProperty(___arguments.context.dataModel, 'phone'));
    teamCaptain = (getObjectProperty(___arguments.context.dataModel, 'teamCaptain'));
    captainAge = (getObjectProperty(___arguments.context.dataModel, 'captainAge'));
    category = (getObjectProperty(___arguments.context.dataModel, 'category'));
    participant2 = (getObjectProperty(___arguments.context.dataModel, 'participant2'));
    participant2age = (getObjectProperty(___arguments.context.dataModel, 'participant2age'));
    participant3 = (getObjectProperty(___arguments.context.dataModel, 'participant3'));
    participant3age = (getObjectProperty(___arguments.context.dataModel, 'participant3age'));
    participant4 = (getObjectProperty(___arguments.context.dataModel, 'participant4'));
    paticipant4age = (getObjectProperty(___arguments.context.dataModel, 'participant4age'));
    participant5 = (getObjectProperty(___arguments.context.dataModel, 'participant5'));
    participant5age = (getObjectProperty(___arguments.context.dataModel, 'participant5age'));
    togetherWith = (getObjectProperty(___arguments.context.dataModel, 'togetherWith'));
    password = (JSON.stringify((mathRandomInt(10000000, 99999999))));
    try {
      if (getValueLength(togetherWith) > 7) {
        if (getValueLength(togetherWith) == 10) {
          lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId LIKE \'',togetherWith,'\''].join(''))).setSortBy('created DESC').setPageSize(1)));
          lastTeam = (getObjectProperty(lastTeam, '0'));
          if (lastTeam !== null && !Array.isArray(lastTeam) && (typeof lastTeam === 'object')) {
            lastCategory = (getObjectProperty(lastTeam, 'category'));
            if (category == lastCategory) {
              group = (getObjectProperty(lastTeam, 'group'));
              routeId = (getObjectProperty(lastTeam, 'routeId'));
              if (category == 'A') {
                lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'').setSortBy('teamId DESC').setPageSize(1)));
                lastTeam = (getObjectProperty(lastTeam, '0'));
                lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
                teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
                if (getValueLength(teamId) == 1) {
                  teamId = String('K-2024-00') + String(teamId);
                } else if (getValueLength(teamId) == 2) {
                  teamId = String('K-2024-0') + String(teamId);
                } else {
                  teamId = String('K-2024-') + String(teamId);
                }
                newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
                ___arguments.context.appData['password'] = password;
                ___arguments.context.appData['teamId'] = teamId;
                ___arguments.context.appData['teamName'] = teamName;
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
              } else if (category == 'B') {
                lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'').setSortBy('teamId DESC').setPageSize(1)));
                lastTeam = (getObjectProperty(lastTeam, '0'));
                lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
                teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
                if (getValueLength(teamId) == 1) {
                  teamId = String('R-2024-00') + String(teamId);
                } else if (getValueLength(teamId) == 2) {
                  teamId = String('R-2024-0') + String(teamId);
                } else {
                  teamId = String('R-2024-') + String(teamId);
                }
                newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
                ___arguments.context.appData['password'] = password;
                ___arguments.context.appData['teamId'] = teamId;
                ___arguments.context.appData['teamName'] = teamName;
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
              } else {
                lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'').setSortBy('teamId DESC').setPageSize(1)));
                lastTeam = (getObjectProperty(lastTeam, '0'));
                lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
                teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
                if (getValueLength(teamId) == 1) {
                  teamId = String('M-2024-00') + String(teamId);
                } else if (getValueLength(teamId) == 2) {
                  teamId = String('M-2024-0') + String(teamId);
                } else {
                  teamId = String('M-2024-') + String(teamId);
                }
                newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
                ___arguments.context.appData['password'] = password;
                ___arguments.context.appData['teamId'] = teamId;
                ___arguments.context.appData['teamName'] = teamName;
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
              }
            } else {
              ___arguments.context.dataModel['errorTxt'] = 'Komanda ar kuru izvēlēts doties kopā, startē citā grupā. ';
              ___arguments.context.dataModel['errorVisibility'] = true;
            }
          } else {
            ___arguments.context.dataModel['errorTxt'] = 'Komanda, ar ko izvēlēts doties kopā, neeksistē! ';
            ___arguments.context.dataModel['errorVisibility'] = true;
          }
        } else {
          ___arguments.context.dataModel['errorTxt'] = 'Ievadīts kļūdains komandas ID!';
          ___arguments.context.dataModel['errorVisibility'] = true;
        }
      } else {
        if (category == 'A') {
          lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'').setSortBy('teamId DESC').setPageSize(1)));
          lastTeam = (getObjectProperty(lastTeam, '0'));
          lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
          lastGroup = (getObjectProperty(lastTeam, 'group'));
          teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
          if (getValueLength(teamId) == 1) {
            teamId = String('K-2024-00') + String(teamId);
          } else if (getValueLength(teamId) == 2) {
            teamId = String('K-2024-0') + String(teamId);
          } else {
            teamId = String('K-2024-') + String(teamId);
          }
          if (lastGroup == 'A1') {
            group = 'A2';
          } else {
            group = 'A1';
          }
          if (group == 'A1') {
            lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('group = \'A1\'').setSortBy('teamId DESC').setPageSize(1)));
            lastTeam = (getObjectProperty(lastTeam, '0'));
            lastRoute = (Number((getObjectProperty(lastTeam, 'routeId'))));
            routeId = lastRoute + 1;
            if (routeId == 9) {
              routeId = 1;
            }
          } else {
            lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('group = \'A2\'').setSortBy('teamId DESC').setPageSize(1)));
            lastTeam = (getObjectProperty(lastTeam, '0'));
            lastRoute = (Number((getObjectProperty(lastTeam, 'routeId'))));
            routeId = lastRoute + 1;
            if (routeId == 17) {
              routeId = 9;
            }
          }
          newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
          ___arguments.context.appData['password'] = password;
          ___arguments.context.appData['teamId'] = teamId;
          ___arguments.context.appData['teamName'] = teamName;
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
        } else if (category == 'B') {
          lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'').setSortBy('teamId DESC').setPageSize(1)));
          lastTeam = (getObjectProperty(lastTeam, '0'));
          lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
          lastGroup = (getObjectProperty(lastTeam, 'group'));
          teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
          if (getValueLength(teamId) == 1) {
            teamId = String('R-2024-00') + String(teamId);
          } else if (getValueLength(teamId) == 2) {
            teamId = String('R-2024-0') + String(teamId);
          } else {
            teamId = String('R-2024-') + String(teamId);
          }
          if (lastGroup == 'B1') {
            group = 'B2';
          } else {
            group = 'B1';
          }
          if (group == 'B1') {
            lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('group = \'B1\'').setSortBy('teamId DESC').setPageSize(1)));
            lastTeam = (getObjectProperty(lastTeam, '0'));
            lastRoute = (Number((getObjectProperty(lastTeam, 'routeId'))));
            routeId = lastRoute + 1;
            if (routeId == 25) {
              routeId = 17;
            }
          } else {
            lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('group = \'B2\'').setSortBy('teamId DESC').setPageSize(1)));
            lastTeam = (getObjectProperty(lastTeam, '0'));
            lastRoute = (Number((getObjectProperty(lastTeam, 'routeId'))));
            routeId = lastRoute + 1;
            if (routeId == 33) {
              routeId = 25;
            }
          }
          newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
          ___arguments.context.appData['password'] = password;
          ___arguments.context.appData['teamId'] = teamId;
          ___arguments.context.appData['teamName'] = teamName;
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
        } else {
          lastTeam = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'').setSortBy('teamId DESC').setPageSize(1)));
          lastTeam = (getObjectProperty(lastTeam, '0'));
          lastTeamId = (getObjectProperty(lastTeam, 'teamId'));
          lastRoute = (Number((getObjectProperty(lastTeam, 'routeId'))));
          teamId = (JSON.stringify((1 + (Number((lastTeamId.slice(7, lastTeamId.length - -1)))))));
          group = 'C';
          if (getValueLength(teamId) == 1) {
            teamId = String('M-2024-00') + String(teamId);
          } else if (getValueLength(teamId) == 2) {
            teamId = String('M-2024-0') + String(teamId);
          } else {
            teamId = String('M-2024-') + String(teamId);
          }
          routeId = lastRoute + 1;
          if (routeId == 41) {
            routeId = 33;
          }
          newTeam = (await Backendless.Data.of('Users').save( ({ [`teamId`]: teamId,[`teamName`]: teamName,[`password`]: password,[`email`]: email,[`phone`]: phone,[`teamCaptain`]: teamCaptain,[`captainAge`]: captainAge,[`category`]: category,[`group`]: group,[`routeId`]: routeId,[`start`]: true,[`participant2`]: participant2,[`participant2age`]: participant2age,[`participant3`]: participant3,[`participant3age`]: participant3age,[`participant4`]: participant4,[`participant4age`]: paticipant4age,[`participant5`]: participant5,[`participant5age`]: participant5age }) ));
          ___arguments.context.appData['password'] = password;
          ___arguments.context.appData['teamId'] = teamId;
          ___arguments.context.appData['teamName'] = teamName;
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('confirmation', undefined);
        }
      }

    } catch (error) {
      if ((getObjectProperty(error, 'message')).indexOf('email') !== -1) {
        ___arguments.context.dataModel['errorTxt'] = 'E-pasts jau ir vienreiz izmantots! Lūdzu, izmantot citu e-pasta adresi.';
      } else {
        ___arguments.context.dataModel['errorTxt'] = 'Neizdevās pievienot komandu, lūdzu, mēģini vēlreiz!';
      }
      ___arguments.context.dataModel['errorVisibility'] = true;

    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['togetherWith'] = 'K-2024-';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/add/components/b3c21ea79abfaab5a59f69d194be2877/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/add/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('P'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/add/components/ff06c3832856b1dbdff34ce40d6a156e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == 'A') {
    ___arguments.context.getComponentDataStoreByUid('318ec8abfef3e34cdd6fdc958fb3b239')['togetherWith'] = 'K-2024-';
  } else if (___arguments.value == 'B') {
    ___arguments.context.getComponentDataStoreByUid('318ec8abfef3e34cdd6fdc958fb3b239')['togetherWith'] = 'R-2024-';
  } else {
    ___arguments.context.getComponentDataStoreByUid('318ec8abfef3e34cdd6fdc958fb3b239')['togetherWith'] = 'M-2024-';
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin/components/83db8015dd7d84362c4f8f7c3b1fe958/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/f3f9d0cf948ccc6f36f1a56beea15399/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      await (async function() {
  	let sortBtn = document.getElementsByTagName("span");
  	sortBtn = sortBtn[sortBtn.length - 2];
  	sortBtn.click();
  })();
  await new Promise(r => setTimeout(r, 500 || 0));
  await (async function() {
  	let sortBtn = document.getElementsByTagName("span");
  	sortBtn = sortBtn[sortBtn.length - 2];
  	sortBtn.click();
  })();

  },
  /* handler:onMounted */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    var cp8Arival, cp7Arival, cp6Arival, cp5Arival, cp4Arival, cp3Arival, cp2Arival, cp1Arival, cp8Name, cp7Name, cp6Name, cp5Name, cp4Name, cp3Name, cp2Name, cp1Name, cp8Id, cp7Id, cp6Id, cp5Id, cp4Id, cp3Id, cp2Id, cp1Id, points, cpList, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['selected'] = ___arguments.selectedRecord;
    points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId=\'',(getObjectProperty(___arguments.selectedRecord, 'teamId')),'\''].join(''))).setPageSize(1)));
    points = (getObjectProperty(points, '0'));
    if (points == null) {
      ___arguments.context.pageData['routeVisibility'] = false;
      ___arguments.context.pageData['noRouteMsgVisibility'] = true;
    } else {
      cpList = (getObjectProperty(points, 'cpAll')).split(',');
      cp1Id = cpList[0];
      cp2Id = cpList[1];
      cp3Id = cpList[2];
      cp4Id = cpList[3];
      cp5Id = cpList[4];
      cp6Id = cpList[5];
      cp7Id = cpList[6];
      cp8Id = cpList[7];
      cp1Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp1Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp1Name = (getObjectProperty((getObjectProperty(cp1Name, '0')), 'controlpointName'));
      cp2Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp2Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp2Name = (getObjectProperty((getObjectProperty(cp2Name, '0')), 'controlpointName'));
      cp3Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp3Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp3Name = (getObjectProperty((getObjectProperty(cp3Name, '0')), 'controlpointName'));
      cp4Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp4Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp4Name = (getObjectProperty((getObjectProperty(cp4Name, '0')), 'controlpointName'));
      cp5Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp5Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp5Name = (getObjectProperty((getObjectProperty(cp5Name, '0')), 'controlpointName'));
      cp6Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp6Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp6Name = (getObjectProperty((getObjectProperty(cp6Name, '0')), 'controlpointName'));
      cp7Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp7Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp7Name = (getObjectProperty((getObjectProperty(cp7Name, '0')), 'controlpointName'));
      cp8Name = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',cp8Id,'\''].join(''))).setProperties(['controlpointId', 'controlpointName']).setPageSize(1)));
      cp8Name = (getObjectProperty((getObjectProperty(cp8Name, '0')), 'controlpointName'));
      cp1Arival = (getObjectProperty(points, 'cp1Arival'));
      if (cp1Arival == null) {
        cp1Arival = 0;
      }
      cp2Arival = (getObjectProperty(points, 'cp2Arival'));
      if (cp2Arival == null) {
        cp2Arival = 0;
      }
      cp3Arival = (getObjectProperty(points, 'cp3Arival'));
      if (cp3Arival == null) {
        cp3Arival = 0;
      }
      cp4Arival = (getObjectProperty(points, 'cp4Arival'));
      if (cp4Arival == null) {
        cp4Arival = 0;
      }
      cp5Arival = (getObjectProperty(points, 'cp5Arival'));
      if (cp5Arival == null) {
        cp5Arival = 0;
      }
      cp6Arival = (getObjectProperty(points, 'cp6Arival'));
      if (cp6Arival == null) {
        cp6Arival = 0;
      }
      cp7Arival = (getObjectProperty(points, 'cp7Arival'));
      if (cp7Arival == null) {
        cp7Arival = 0;
      }
      cp8Arival = (getObjectProperty(points, 'cp8Arival'));
      if (cp8Arival == null) {
        cp8Arival = 0;
      }
      ___arguments.context.pageData['cp1Id'] = cp1Id;
      ___arguments.context.pageData['cp2Id'] = cp2Id;
      ___arguments.context.pageData['cp3Id'] = cp3Id;
      ___arguments.context.pageData['cp4Id'] = cp4Id;
      ___arguments.context.pageData['cp5Id'] = cp5Id;
      ___arguments.context.pageData['cp6Id'] = cp6Id;
      ___arguments.context.pageData['cp7Id'] = cp7Id;
      ___arguments.context.pageData['cp8Id'] = cp8Id;
      ___arguments.context.pageData['cp1Name'] = cp1Name;
      ___arguments.context.pageData['cp2Name'] = cp2Name;
      ___arguments.context.pageData['cp3Name'] = cp3Name;
      ___arguments.context.pageData['cp4Name'] = cp4Name;
      ___arguments.context.pageData['cp5Name'] = cp5Name;
      ___arguments.context.pageData['cp6Name'] = cp6Name;
      ___arguments.context.pageData['cp7Name'] = cp7Name;
      ___arguments.context.pageData['cp8Name'] = cp8Name;
      ___arguments.context.pageData['cp1Arival'] = cp1Arival;
      ___arguments.context.pageData['cp2Arival'] = cp2Arival;
      ___arguments.context.pageData['cp3Arival'] = cp3Arival;
      ___arguments.context.pageData['cp4Arival'] = cp4Arival;
      ___arguments.context.pageData['cp5Arival'] = cp5Arival;
      ___arguments.context.pageData['cp6Arival'] = cp6Arival;
      ___arguments.context.pageData['cp7Arival'] = cp7Arival;
      ___arguments.context.pageData['cp8Arival'] = cp8Arival;
      ___arguments.context.pageData['noRouteMsgVisibility'] = false;
      ___arguments.context.pageData['routeVisibility'] = true;
    }
    ___arguments.context.pageData['modalVisibility'] = true;

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/admin/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('A'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin/components/ef614f9c1fd406be1bb4f53411fa4dce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (pageName, pageData) { BackendlessUI.goToPage(pageName, pageData) })('statistics', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/confirmation/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('P'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    ___arguments.context.pageData['teamName'] = (getObjectProperty(___arguments.context.appData, 'teamName'));
    ___arguments.context.pageData['teamId'] = (getObjectProperty(___arguments.context.appData, 'teamId'));
    ___arguments.context.pageData['password'] = (getObjectProperty(___arguments.context.appData, 'password'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/confirmation/components/504aa5e7eb97689911eacdff963e8836/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (pageName, pageData) { BackendlessUI.goToPage(pageName, pageData) })('add', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/confirmation/components/143158d98234cfc8dac788c1afafc2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/cabbf39b74aa90bd8f177302a6654ed8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var teamId, teamName, nextControlpoint, points, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    teamId = (getObjectProperty(___arguments.context.pageData, 'teamId'));
    if (teamId.length == 10) {
      if ((teamId.startsWith('K')) || (teamId.startsWith('R')) || (teamId.startsWith('M'))) {
        points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
        points = (getObjectProperty(points, '0'));
        if (points == null) {
          ___arguments.context.pageData['teamMsg'] = 'Šāda komanda neeksistē!';
        } else {
          nextControlpoint = (getObjectProperty(points, 'cpToGo'));
          if (nextControlpoint == null) {
            ___arguments.context.pageData['teamMsg'] = 'Šī komanda jau ir apmeklējusi visus kontrolpunktus!';
          } else {
            nextControlpoint = nextControlpoint.split(',');
            nextControlpoint = nextControlpoint[0];
            if (nextControlpoint != (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'))) {
              ___arguments.context.pageData['teamMsg'] = 'Šai komandai nav jāapmeklē šis kontrolpunkts';
            } else {
              teamName = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
              teamName = (getObjectProperty(teamName, '0'));
              teamName = (getObjectProperty(teamName, 'teamName'));
              ___arguments.context.pageData['teamMsg'] = teamName;
              ___arguments.context.pageData['teamName'] = teamName;
              ___arguments.context.pageData['sportTeamId'] = teamId;
            }
          }
        }
      }
    } else {
      ___arguments.context.pageData['teamMsg'] = null;
      ___arguments.context.pageData['sportTeamId'] = null;
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/controlpoint/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var thisControlpoint, controlpointTeamCount, teamCountTotal, controlpoint, controlpointName, teamId, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('T'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    teamId = (getObjectProperty(team, 'teamId'));
    if (teamId.charAt(3) == '-') {
      thisControlpoint = teamId.slice(1, 3);
    } else {
      thisControlpoint = teamId.slice(1, 4);
    }
    controlpoint = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',thisControlpoint,'\''].join(''))).setPageSize(1)));
    controlpoint = (getObjectProperty(controlpoint, '0'));
    controlpointName = (getObjectProperty(controlpoint, 'controlpointName'));
    ___arguments.context.pageData['thisControlpoint'] = thisControlpoint;
    ___arguments.context.pageData['controlpointName'] = controlpointName;
    ___arguments.context.pageData['controlpointGroup'] = (getObjectProperty(controlpoint, 'controlpointGroup'));
    teamCountTotal = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpAll LIKE \'%',thisControlpoint,',%\' OR cpAll LIKE \'%',thisControlpoint,'\''].join('')))));
    controlpointTeamCount = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpToGo LIKE \'%',thisControlpoint,',%\' OR cpToGo LIKE \'%',thisControlpoint,'\''].join('')))));
    controlpointTeamCount = teamCountTotal - controlpointTeamCount;
    ___arguments.context.pageData['teamCount'] = ([(Number(controlpointTeamCount)),'/',(Number(teamCountTotal))].join(''));
    ___arguments.context.pageData['controlpointTeamCount'] = controlpointTeamCount;
    if (thisControlpoint.startsWith('A')) {
      ___arguments.context.pageData['teamId'] = 'K-2024-';
    } else if (thisControlpoint.startsWith('B')) {
      ___arguments.context.pageData['teamId'] = 'R-2024-';
    } else {
      ___arguments.context.pageData['teamId'] = 'M-2024-';
    }
    ___arguments.context.pageData['sportTeamId'] = null;
    ___arguments.context.pageData['cpPoints'] = null;
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/controlpoint/components/44f378efb1d842d786a95e1d7af59edb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, controlpointTeamCount, teamCountTotal, thisControlpoint, pointsSaved, points, teamId, cpToGo, cpPoints, quizPoints, arivalPoints, teamName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    cpPoints = (getObjectProperty(___arguments.context.pageData, 'cpPoints'));
    teamId = (getObjectProperty(___arguments.context.pageData, 'sportTeamId'));
    teamName = (getObjectProperty(___arguments.context.pageData, 'teamName'));
    ___arguments.context.pageData['teamMsg'] = teamName;
    if (teamId == null) {
      ___arguments.context.pageData['teamMsg'] = 'Nav ievadīts komandas ID!';
    } else {
      if (cpPoints == null) {
        ___arguments.context.pageData['teamMsg'] = 'Nav ievadīti iegūtie punkti!';
      } else {
        if (!(cpPoints >= 0 && cpPoints <= 25)) {
          ___arguments.context.pageData['teamMsg'] = 'Ievadītajiem punktiem jābūt robežās 0-25!';
        } else {
          points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
          points = (getObjectProperty(points, '0'));
          thisControlpoint = (getObjectProperty(points, 'cpToGo'));
          thisControlpoint = thisControlpoint.split(',');
          if (!(thisControlpoint[0] == (getObjectProperty(___arguments.context.pageData, 'thisControlpoint')))) {
            ___arguments.context.pageData['teamMsg'] = 'Komandai punkti jau ir ievadīti!';
          } else {
            thisControlpoint = 9 - thisControlpoint.length;
            arivalPoints = (getObjectProperty(points, (['cp',thisControlpoint,'Arival'].join(''))));
            quizPoints = (getObjectProperty(points, (['cp',thisControlpoint,'Quiz'].join(''))));
            if (arivalPoints == null && quizPoints == null) {
              ___arguments.context.pageData['noPointsMsg'] = 'Komandai nav ierašanās un viktorīnas punkti! Vai ievadīt ierašanās, viktorīnas un sporta punktus? Ievadot punktus komandai nebūs iespēja atbildēt uz viktorīnas jautājumiem un tā saņems 0 punktus par viktorīnu!';
              ___arguments.context.pageData['modalVisibilityNoPoints'] = true;
            } else if (arivalPoints == null) {
              ___arguments.context.pageData['noPointsMsg'] = 'Komandai nav ierašanās punkti! Vai ievadīt ierašanās un sporta punktus?';
              ___arguments.context.pageData['modalVisibilityNoPoints'] = true;
            } else if (quizPoints == null) {
              ___arguments.context.pageData['noPointsMsg'] = 'Komandai nav viktorīnas punkti! Vai ievadīt viktorīnas un sporta punktus? Ievadot punktus komandai nebūs iespēja atbildēt uz viktorīnas jautājumiem un tā saņems 0 punktus par viktorīnu!';
              ___arguments.context.pageData['modalVisibilityNoPoints'] = true;
            } else {
              try {
                points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
                points = (getObjectProperty(points, '0'));
                cpToGo = (getObjectProperty(points, 'cpToGo'));
                cpToGo = cpToGo.split(',');
                if (cpToGo.length != 1) {
                  cpToGo.shift();
                  cpToGo = cpToGo.join(',');
                } else {
                  cpToGo = null;
                }
                points[(['cp',thisControlpoint,'Sport'].join(''))] = cpPoints;
                await Backendless.Data.of('Points').save( points );
                pointsSaved = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
                pointsSaved = (getObjectProperty(pointsSaved, '0'));
                if ((getObjectProperty(points, (['cp',thisControlpoint,'Sport'].join('')))) != (getObjectProperty(pointsSaved, (['cp',thisControlpoint,'Sport'].join(''))))) {
                  throw (new Error('Kļūda, saglabājot rezultātu!'))
                }
                points['cpToGo'] = cpToGo;
                await Backendless.Data.of('Points').save( points );
                pointsSaved = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
                pointsSaved = (getObjectProperty(pointsSaved, '0'));
                if ((getObjectProperty(points, 'cpToGo')) != (getObjectProperty(pointsSaved, 'cpToGo'))) {
                  throw (new Error('Kļūda, saglabājot rezultātu!'))
                }
                ___arguments.context.pageData['teamMsg'] = 'Rezultāts saglabāts!';
                ___arguments.context.pageData['sportTeamId'] = null;
                ___arguments.context.pageData['cpPoints'] = null;
                ___arguments.context.pageData['teamName'] = null;
                thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
                if (thisControlpoint.startsWith('A')) {
                  ___arguments.context.pageData['teamId'] = 'K-2024-';
                } else if (thisControlpoint.startsWith('B')) {
                  ___arguments.context.pageData['teamId'] = 'R-2024-';
                } else {
                  ___arguments.context.pageData['teamId'] = 'M-2024-';
                }
                teamCountTotal = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpAll LIKE \'%',thisControlpoint,'%\''].join('')))));
                controlpointTeamCount = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpToGo LIKE \'%',thisControlpoint,'%\''].join('')))));
                controlpointTeamCount = teamCountTotal - controlpointTeamCount;
                ___arguments.context.pageData['teamCount'] = ([(Number(controlpointTeamCount)),'/',(Number(teamCountTotal))].join(''));
                ___arguments.context.pageData['controlpointTeamCount'] = controlpointTeamCount;

              } catch (error) {
                ___arguments.context.pageData['teamMsg'] = 'Kļūda, saglabājot rezultātu!';

              }
            }
          }
        }
      }
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/794537472b49d3647783ba0ecfd67049/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/b59b2b487a290e34c2ebcefe6f827ea7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error, thisControlpoint;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
    if (thisControlpoint.startsWith('A')) {
      ___arguments.context.pageData['correctTeamId'] = 'K-2024-';
    } else if (thisControlpoint.startsWith('B')) {
      ___arguments.context.pageData['correctTeamId'] = 'R-2024-';
    } else {
      ___arguments.context.pageData['correctTeamId'] = 'M-2024-';
    }
    ___arguments.context.pageData['correctTeamMsg'] = null;
    ___arguments.context.pageData['correctId'] = null;
    ___arguments.context.pageData['correctName'] = null;
    ___arguments.context.pageData['correctPoints'] = null;
    ___arguments.context.pageData['modalVisibility'] = true;

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/dc9177cd97df7e897ae635e5478ca0d5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var teamName, correctTeamId, cpInRoute, points, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    correctTeamId = (getObjectProperty(___arguments.context.pageData, 'correctTeamId'));
    if (correctTeamId.length == 10) {
      if ((correctTeamId.startsWith('K')) || (correctTeamId.startsWith('R')) || (correctTeamId.startsWith('M'))) {
        points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',correctTeamId,'\''].join(''))).setPageSize(1)));
        points = (getObjectProperty(points, '0'));
        if (points == null) {
          ___arguments.context.pageData['correctTeamMsg'] = 'Šāda komanda neeksistē!';
        } else {
          cpInRoute = (getObjectProperty(points, 'cpAll'));
          if (!(cpInRoute.indexOf((getObjectProperty(___arguments.context.pageData, 'thisControlpoint'))) !== -1)) {
            ___arguments.context.pageData['correctTeamMsg'] = 'Šai komandai nav jāapmeklē šis kontrolpunkts';
          } else {
            teamName = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',correctTeamId,'\''].join(''))).setPageSize(1)));
            teamName = (getObjectProperty(teamName, '0'));
            teamName = (getObjectProperty(teamName, 'teamName'));
            ___arguments.context.pageData['correctTeamMsg'] = teamName;
            ___arguments.context.pageData['correctId'] = correctTeamId;
            ___arguments.context.pageData['correctName'] = teamName;
          }
        }
      }
    } else {
      ___arguments.context.pageData['correctTeamMsg'] = null;
      ___arguments.context.pageData['correctId'] = null;
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/controlpoint/components/4645027a6d8e146dc5b3857f0974361b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var thisControlpoint, pointsSaved, cpNumber, points, teamId, cpPoints, cpAll, pointsSport, teamName, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  teamId = (getObjectProperty(___arguments.context.pageData, 'correctId'));
  teamName = (getObjectProperty(___arguments.context.pageData, 'correctName'));
  cpPoints = (getObjectProperty(___arguments.context.pageData, 'correctPoints'));
  thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
  if (teamId == null) {
    ___arguments.context.pageData['correctTeamMsg'] = 'Nav ievadīts komandas ID!';
  } else {
    if (cpPoints == null) {
      ___arguments.context.pageData['correctTeamMsg'] = 'Nav ievadīti iegūtie punkti!';
    } else {
      if (!(cpPoints >= 0 && cpPoints <= 25)) {
        ___arguments.context.pageData['correctTeamMsg'] = 'Ievadītajiem punktiem jābūt robežās 0-25!';
      } else {
        points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
        points = (getObjectProperty(points, '0'));
        cpAll = (getObjectProperty(points, 'cpAll'));
        cpAll = cpAll.split(',');
        cpNumber = cpAll.indexOf(thisControlpoint) + 1;
        pointsSport = (getObjectProperty(points, (['cp',cpNumber,'Sport'].join(''))));
        if (pointsSport == null) {
          ___arguments.context.pageData['correctTeamMsg'] = 'Šī komanda vēl nav bijusi šajā kontrolpunktā!';
        } else {
          try {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            cpAll = (getObjectProperty(points, 'cpAll'));
            cpAll = cpAll.split(',');
            cpNumber = cpAll.indexOf(thisControlpoint) + 1;
            points[(['cp',cpNumber,'Sport'].join(''))] = cpPoints;
            await Backendless.Data.of('Points').save( points );
            pointsSaved = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            pointsSaved = (getObjectProperty(pointsSaved, '0'));
            if ((getObjectProperty(points, (['cp',cpNumber,'Sport'].join('')))) != (getObjectProperty(pointsSaved, (['cp',cpNumber,'Sport'].join(''))))) {
              throw (new Error('Kļūda, saglabājot rezultātu!'))
            }
            ___arguments.context.pageData['correctTeamMsg'] = 'Rezultāts saglabāts!';
            ___arguments.context.pageData['correctId'] = null;
            ___arguments.context.pageData['correctPoints'] = null;
            ___arguments.context.pageData['correctName'] = null;
            if (thisControlpoint.startsWith('A')) {
              ___arguments.context.pageData['correctTeamId'] = 'K-2024-';
            } else if (thisControlpoint.startsWith('B')) {
              ___arguments.context.pageData['correctTeamId'] = 'R-2024-';
            } else {
              ___arguments.context.pageData['correctTeamId'] = 'M-2024-';
            }

          } catch (error) {
            ___arguments.context.pageData['correctTeamMsg'] = 'Kļūda, saglabājot rezultātu!';

          }
        }
      }
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/eb0a9e4cdbf7206448fb99a2ffa9aab5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var thisControlpoint;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['sportTeamId'] = null;
  ___arguments.context.pageData['cpPoints'] = null;
  thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
  if (thisControlpoint.startsWith('A')) {
    ___arguments.context.pageData['teamId'] = 'K-2023-';
  } else if (thisControlpoint.startsWith('B')) {
    ___arguments.context.pageData['teamId'] = 'R-2023-';
  } else {
    ___arguments.context.pageData['teamId'] = 'M-2023-';
  }
  ___arguments.context.pageData['modalVisibilityNoPoints'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/e6c374142dd48e3c2366f97dc1aa5b4a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var arivalPoints, controlpointTeamCount, cpPoints, cpToGo, points, pointsSaved, quizPoints, teamCountTotal, teamId, thisControlpoint;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cpPoints = (getObjectProperty(___arguments.context.pageData, 'cpPoints'));
  teamId = (getObjectProperty(___arguments.context.pageData, 'sportTeamId'));
  if (teamId == null) {
    ___arguments.context.pageData['teamMsg'] = 'Nav ievadīts komandas ID!';
    ___arguments.context.pageData['noPointsMsg'] = null;
    ___arguments.context.pageData['sportTeamId'] = null;
    ___arguments.context.pageData['cpPoints'] = null;
    ___arguments.context.pageData['teamName'] = null;
    ___arguments.context.pageData['modalVisibilityNoPoints'] = false;
  } else {
    if (cpPoints == null) {
      ___arguments.context.pageData['teamMsg'] = 'Nav ievadīti iegūtie punkti!';
      ___arguments.context.pageData['noPointsMsg'] = null;
      ___arguments.context.pageData['modalVisibilityNoPoints'] = false;
    } else {
      if (!(cpPoints >= 0 && cpPoints <= 50)) {
        ___arguments.context.pageData['teamMsg'] = 'Ievadītajiem punktiem jābūt robežās 0-50!';
        ___arguments.context.pageData['noPointsMsg'] = null;
        ___arguments.context.pageData['modalVisibilityNoPoints'] = false;
      } else {
        points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
        points = (getObjectProperty(points, '0'));
        thisControlpoint = (getObjectProperty(points, 'cpToGo'));
        thisControlpoint = thisControlpoint.split(',');
        thisControlpoint = 9 - thisControlpoint.length;
        arivalPoints = (getObjectProperty(points, (['cp',thisControlpoint,'Arival'].join(''))));
        quizPoints = (getObjectProperty(points, (['cp',thisControlpoint,'Quiz'].join(''))));
        if (arivalPoints == null && quizPoints == null) {
          try {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            cpToGo = (getObjectProperty(points, 'cpToGo'));
            cpToGo = cpToGo.split(',');
            if (cpToGo.length != 1) {
              cpToGo.shift();
              cpToGo = cpToGo.join(',');
            } else {
              cpToGo = null;
            }
            points[(['cp',thisControlpoint,'Sport'].join(''))] = cpPoints;
            points[(['cp',thisControlpoint,'Arival'].join(''))] = 10;
            points[(['cp',thisControlpoint,'Quiz'].join(''))] = 0;
            points['cpToGo'] = cpToGo;
            await Backendless.Data.of('Points').save( points );
            pointsSaved = (getObjectProperty(points, '0'));
            ___arguments.context.pageData['teamMsg'] = 'Rezultāts saglabāts!';
            ___arguments.context.pageData['sportTeamId'] = null;
            ___arguments.context.pageData['cpPoints'] = null;
            ___arguments.context.pageData['teamName'] = null;
            thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
            if (thisControlpoint.startsWith('A')) {
              ___arguments.context.pageData['teamId'] = 'K-2024-';
            } else if (thisControlpoint.startsWith('B')) {
              ___arguments.context.pageData['teamId'] = 'R-2024-';
            } else {
              ___arguments.context.pageData['teamId'] = 'M-2024-';
            }
            teamCountTotal = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpAll LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpToGo LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = teamCountTotal - controlpointTeamCount;
            ___arguments.context.pageData['teamCount'] = ([(Number(controlpointTeamCount)),'/',(Number(teamCountTotal))].join(''));
            ___arguments.context.pageData['controlpointTeamCount'] = controlpointTeamCount;
            ___arguments.context.pageData['noPointsMsg'] = null;
            ___arguments.context.pageData['modalVisibilityNoPoints'] = false;

          } catch (error) {
            ___arguments.context.pageData['teamMsg'] = 'Kļūda, saglabājot rezultātu!';

          }
        } else if (arivalPoints == null) {
          try {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            cpToGo = (getObjectProperty(points, 'cpToGo'));
            cpToGo = cpToGo.split(',');
            if (cpToGo.length != 1) {
              cpToGo.shift();
              cpToGo = cpToGo.join(',');
            } else {
              cpToGo = null;
            }
            points[(['cp',thisControlpoint,'Sport'].join(''))] = cpPoints;
            points[(['cp',thisControlpoint,'Arival'].join(''))] = 10;
            points['cpToGo'] = cpToGo;
            await Backendless.Data.of('Points').save( points );
            ___arguments.context.pageData['teamMsg'] = 'Rezultāts saglabāts!';
            ___arguments.context.pageData['sportTeamId'] = null;
            ___arguments.context.pageData['cpPoints'] = null;
            ___arguments.context.pageData['teamName'] = null;
            thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
            if (thisControlpoint.startsWith('A')) {
              ___arguments.context.pageData['teamId'] = 'K-2023-';
            } else if (thisControlpoint.startsWith('B')) {
              ___arguments.context.pageData['teamId'] = 'R-2023-';
            } else {
              ___arguments.context.pageData['teamId'] = 'M-2023-';
            }
            teamCountTotal = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpAll LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpToGo LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = teamCountTotal - controlpointTeamCount;
            ___arguments.context.pageData['teamCount'] = ([(Number(controlpointTeamCount)),'/',(Number(teamCountTotal))].join(''));
            ___arguments.context.pageData['controlpointTeamCount'] = controlpointTeamCount;
            ___arguments.context.pageData['noPointsMsg'] = null;
            ___arguments.context.pageData['modalVisibilityNoPoints'] = false;

          } catch (error) {
            ___arguments.context.pageData['teamMsg'] = 'Kļūda, saglabājot rezultātu!';

          }
        } else if (quizPoints == null) {
          try {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            cpToGo = (getObjectProperty(points, 'cpToGo'));
            cpToGo = cpToGo.split(',');
            if (cpToGo.length != 1) {
              cpToGo.shift();
              cpToGo = cpToGo.join(',');
            } else {
              cpToGo = null;
            }
            points[(['cp',thisControlpoint,'Sport'].join(''))] = cpPoints;
            points[(['cp',thisControlpoint,'Quiz'].join(''))] = 0;
            points['cpToGo'] = cpToGo;
            await Backendless.Data.of('Points').save( points );
            ___arguments.context.pageData['teamMsg'] = 'Rezultāts saglabāts!';
            ___arguments.context.pageData['sportTeamId'] = null;
            ___arguments.context.pageData['cpPoints'] = null;
            ___arguments.context.pageData['teamName'] = null;
            thisControlpoint = (getObjectProperty(___arguments.context.pageData, 'thisControlpoint'));
            if (thisControlpoint.startsWith('A')) {
              ___arguments.context.pageData['teamId'] = 'K-2023-';
            } else if (thisControlpoint.startsWith('B')) {
              ___arguments.context.pageData['teamId'] = 'R-2023-';
            } else {
              ___arguments.context.pageData['teamId'] = 'M-2023-';
            }
            teamCountTotal = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpAll LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = (await Backendless.Data.of('Points').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['cpToGo LIKE \'%',thisControlpoint,'%\''].join('')))));
            controlpointTeamCount = teamCountTotal - controlpointTeamCount;
            ___arguments.context.pageData['teamCount'] = ([(Number(controlpointTeamCount)),'/',(Number(teamCountTotal))].join(''));
            ___arguments.context.pageData['controlpointTeamCount'] = controlpointTeamCount;
            ___arguments.context.pageData['noPointsMsg'] = null;
            ___arguments.context.pageData['modalVisibilityNoPoints'] = false;

          } catch (error) {
            ___arguments.context.pageData['teamMsg'] = 'Kļūda, saglabājot rezultātu!';

          }
        } else {
          ___arguments.context.pageData['noPointsMsg'] = null;
          ___arguments.context.pageData['modalVisibilityNoPoints'] = false;
        }
      }
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/controlpoint/components/c20dcbfbf3e9185bf901e3df3906015f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('cpList', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/cpList/components/83db8015dd7d84362c4f8f7c3b1fe958/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/cpList/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var thisControlpoint, teamId, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('T'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    teamId = (getObjectProperty(team, 'teamId'));
    if (teamId.charAt(3) == '-') {
      thisControlpoint = teamId.slice(1, 3);
    } else {
      thisControlpoint = teamId.slice(1, 4);
    }
    ___arguments.context.pageData['where'] = (['cpToGo LIKE \'%',thisControlpoint,',%\'','OR cpToGo LIKE \'%',thisControlpoint,'\''].join(''));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/cpList/components/ef614f9c1fd406be1bb4f53411fa4dce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('controlpoint', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/finish/components/bb527e26d84340d13d99f68a4502119d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/finish/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var gameStatus, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('F'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    gameStatus = (await Backendless.Data.of('Game').findFirst(Backendless.DataQueryBuilder.create().setProperties('gameStatus')));
    if ((getObjectProperty(gameStatus, 'gameStatus')) == 'Started') {
      ___arguments.context.pageData['endBtnVisibility'] = true;
    } else {
      ___arguments.context.pageData['endBtnVisibility'] = false;
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/finish/components/ded62248b67c8c58e751fca628bcf41a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['modalVisibility'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/finish/components/2f77a09ce837527fee061b57d7f97f53/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['modalVisibility'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/finish/components/7025864358d2042617a3aff8d68d121c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var game;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  game = (await Backendless.Data.of('Game').find(Backendless.DataQueryBuilder.create().setPageSize(1)));
  game = (getObjectProperty(game, '0'));
  if ((getObjectProperty(game, 'gameStatus')) == 'Started') {
    try {
      game['gameStatus'] = 'Finished';
      await Backendless.Data.of('Game').save( game );
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('results', undefined);

    } catch (error) {
      ___arguments.context.pageData['errorTxt'] = 'Kļuda beidzot spēli! Mēģini vēlreiz!';
      ___arguments.context.pageData['errorVisibility'] = true;

    }
  } else {
    ___arguments.context.pageData['errorTxt'] = 'Spēle nav sākusies!';
    ___arguments.context.pageData['errorVisibility'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/info/components/7cf68057c6c58ad6843ddd24d5d2ab83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/info/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    if ((getObjectProperty(team, 'category')) == 'T' || [(new Date((new Date())).getUTCDate()),'.',(new Date((new Date())).getUTCMonth()),'.',(new Date((new Date())).getUTCFullYear())].join('') == '4.4.2023') {
      ___arguments.context.pageData['btnBackVisibility'] = true;
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/info/components/4354c856935b7d76c25d6a73dbb5a3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f']((await Backendless.UserService.getCurrentUser(true)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/judge-info/components/7cf68057c6c58ad6843ddd24d5d2ab83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/judge-info/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    if ((getObjectProperty(team, 'category')) != 'T') {
      await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
    } else {
      if ((getObjectProperty(team, 'category')) == 'T' || [(new Date((new Date())).getUTCDate()),'.',(new Date((new Date())).getUTCMonth()),'.',(new Date((new Date())).getUTCFullYear())].join('') == '4.4.2023') {
        ___arguments.context.pageData['btnBackVisibility'] = true;
      }
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/judge-info/components/4354c856935b7d76c25d6a73dbb5a3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f']((await Backendless.UserService.getCurrentUser(true)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, team, password, username, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Lūdzu, uzgaidi...' : 'Pieslēgties');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      team = (await Backendless.UserService.login( username, password, true  ));
      await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3003) {
        await setError((new Error('Nepareizs komandas ID un/vai parole')));
        await updateButtonLogin(false);
      } else {
        await setError((new Error('Servera kļūda. Mēģini atkārtoti vai sazinies ar lietotāju atbalstu.')));
        await updateButtonLogin(false);
      }

    }
  } else {
    await setError((new Error('Komandas ID un parolei jābūt ievadītiem')));
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team;


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team != null) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/login/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, username, x, success;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function setSuccess(success) {
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['success'] = success;
}


  await setError(null);
  await setSuccess(null);
  username = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username'));
  if (username != null) {
    try {
      await Backendless.UserService.restorePassword(username);
      await setSuccess((new Error('Uz Jūsu e-pastu nosūtīta saite paroles atjaunošanai')));

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3020) {
        await setError((new Error('Komandas ID netika atrasts!')));
      } else {
        await setError((new Error('Problēma veicot paroles nomaiņu, lūdzu, mēģini vēlreiz!')));
      }

    }
  } else {
    await setError((new Error('Komandas ID jābūt ievadītam')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/notregistred/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (getObjectProperty(team, 'start')) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/notregistred/components/6b9e202c9aa95e567aa70061ce2196e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/participant/components/805ac61b7046b2d400b228ccfe7c04da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/participant/components/820697324403b2b7c50646febece6c85/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/participant/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var points, cpList, controlpoint, nextCp, result, teamId, team, gameStatus, teamName, cpAll, route, i, routeId, pointsData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!(((getObjectProperty(team, 'teamId')).startsWith('K')) || ((getObjectProperty(team, 'teamId')).startsWith('R')) || ((getObjectProperty(team, 'teamId')).startsWith('M')))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    if ([(new Date((new Date())).getUTCDate()),'.',(new Date((new Date())).getUTCMonth()),'.',(new Date((new Date())).getUTCFullYear())].join('') == '4.4.2024') {
      teamId = (getObjectProperty(team, 'teamId'));
      pointsData = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
      if (!pointsData.length) {
        team = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
        team = (getObjectProperty(team, '0'));
        teamId = (getObjectProperty(team, 'teamId'));
        teamId = (getObjectProperty(team, 'teamId'));
        teamName = (getObjectProperty(team, 'teamName'));
        routeId = (getObjectProperty(team, 'routeId'));
        route = (await Backendless.Data.of('Routes').find(Backendless.DataQueryBuilder.create().setWhereClause((['routeId = \'',routeId,'\''].join(''))).setPageSize(1)));
        route = (getObjectProperty(route, '0'));
        cpAll = '';
        for (i = 1; i <= 8; i++) {
          if (i != 8) {
            cpAll = [cpAll,(getObjectProperty(route, (['cp',i,'Id'].join('')))),','].join('');
          } else {
            cpAll = String(cpAll) + String(getObjectProperty(route, (['cp',i,'Id'].join(''))));
          }
        }
        try {
          await Backendless.Data.of('Points').save( ({ [`teamId`]: teamId,[`cpAll`]: cpAll,[`cpToGo`]: cpAll,[`teamName`]: teamName }) );

        } catch (error) {

        }
      }
      if (!(getObjectProperty(team, 'start'))) {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('notregistred', undefined);
      } else {
        gameStatus = (await Backendless.Data.of('Game').findFirst(Backendless.DataQueryBuilder.create().setProperties('gameStatus')));
        gameStatus = (getObjectProperty(gameStatus, 'gameStatus'));
        if (gameStatus == 'Not Started') {
          ___arguments.context.pageData['messageVisibility'] = true;
          ___arguments.context.pageData['messageTxt'] = 'Gaidi spēles sākumu!';
        } else if (gameStatus == 'Finished') {
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('results', undefined);
        } else {
          if (getObjectProperty(team, 'finished')) {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            result = (await BackendlessUI.Functions.Custom['fn_bc01a48af416ec799ecfaa9af64b0313'](points));
            ___arguments.context.pageData['points'] = (getObjectProperty(result, 'pointsTotal'));
            ___arguments.context.pageData['messageTxt'] = 'Apsveicam, Tava komanda ir finišējusi! Gaidi spēles beigas, lai uzzinātu rezultātus.';
            ___arguments.context.pageData['messageVisibility'] = true;
            ___arguments.context.pageData['infoVisibility'] = false;
          } else {
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',teamId,'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            result = (await BackendlessUI.Functions.Custom['fn_bc01a48af416ec799ecfaa9af64b0313'](points));
            ___arguments.context.pageData['points'] = (getObjectProperty(result, 'pointsTotal'));
            if (!((getObjectProperty(points, 'cpToGo')) == null)) {
              cpList = (getObjectProperty(points, 'cpToGo')).split(',');
              nextCp = cpList[0];
            } else {
              nextCp = 'F';
            }
            if (nextCp == 'F') {
              controlpoint = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause('controlpointId = \'Finišs\'').excludeProperties(['controlpointName', 'controlpointKey']).setPageSize(1)));
              controlpoint = (getObjectProperty(controlpoint, '0'));
              ___arguments.context.pageData['controlpointId'] = (getObjectProperty(controlpoint, 'controlpointId'));
              ___arguments.context.pageData['controlpointImg'] = (getObjectProperty(controlpoint, 'controlpointImg'));
              ___arguments.context.pageData['controlpointInfo'] = (getObjectProperty(controlpoint, 'controlpointDescription'));
              ___arguments.context.pageData['infoVisibility'] = true;
            } else {
              controlpoint = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',nextCp,'\''].join(''))).excludeProperties(['controlpointName', 'controlpointKey']).setPageSize(1)));
              controlpoint = (getObjectProperty(controlpoint, '0'));
              ___arguments.context.pageData['controlpointId'] = (getObjectProperty(controlpoint, 'controlpointId'));
              ___arguments.context.pageData['controlpointImg'] = (getObjectProperty(controlpoint, 'controlpointImg'));
              ___arguments.context.pageData['controlpointInfo'] = (getObjectProperty(controlpoint, 'controlpointDescription'));
              ___arguments.context.pageData['infoVisibility'] = true;
              if ((getObjectProperty(points, (['cp',9 - cpList.length,'Quiz'].join('')))) == null) {
                ___arguments.context.pageData['keyVisibility'] = true;
              } else {
                ___arguments.context.pageData['keyVisibility'] = false;
              }
            }
          }
        }
      }
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('info', undefined);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/participant/components/4e4b8317ed4cf4128efda13d8587ab7b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, points, controlpointNumber, pointsSaved, team, cpList, controlpointId, keyId, key;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}

/**
 * Describe this function...
 */
async function error2(error) {
  ___arguments.context.pageData['errorTxt'] = error;
  ___arguments.context.pageData['errorVisibility'] = true;
}

/**
 * Describe this function...
 */
async function notError() {
  ___arguments.context.pageData['errorVisibility'] = false;
}


  try {
    key = (getObjectProperty(___arguments.context.pageData, 'controlpointKey'));
    controlpointId = (getObjectProperty(___arguments.context.pageData, 'controlpointId'));
    team = (await Backendless.UserService.getCurrentUser(false));
    if (typeof key === 'undefined') {
      await error2('Nav ievadīta atslēga!');
    } else {
      if (getValueLength(key) == 0) {
        await error2('Nav ievadīta atslēga!');
      } else {
        keyId = (await Backendless.Data.of('Controlpoints').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointKey = \'',key,'\''].join(''))).setProperties('controlpointId').setPageSize(1)));
        if (keyId.length == 0) {
          await error2('Ievadīta nepareiza atslēga!');
        } else {
          keyId = (getObjectProperty(keyId, '0'));
          if (controlpointId == (getObjectProperty(keyId, 'controlpointId'))) {
            await notError();
            points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
            points = (getObjectProperty(points, '0'));
            cpList = (getObjectProperty(points, 'cpToGo')).split(',');
            controlpointNumber = 9 - cpList.length;
            if (!(controlpointId == cpList[0])) {
              (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
            } else {
              if ((getObjectProperty(points, (['cp',controlpointNumber,'Arival'].join('')))) == null) {
                try {
                  points[(['cp',controlpointNumber,'Arival'].join(''))] = 10;
                  await Backendless.Data.of('Points').save( points );
                  pointsSaved = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
                  pointsSaved = (getObjectProperty(pointsSaved, '0'));
                  if ((getObjectProperty(points, (['cp',controlpointNumber,'Arival'].join('')))) != (getObjectProperty(pointsSaved, (['cp',controlpointNumber,'Arival'].join(''))))) {
                    throw (new Error('Punkti netika saglabāt! Pārlādē lapu un mēģini vēlreiz'))
                  }

                } catch (error) {
                  ___arguments.context.pageData['errorTxt'] = 'Neizdevās ievadīt atslēgu! Pārlādē lapu un mēģini vēlreiz!';

                }
              }
              if ((getObjectProperty(points, (['cp',controlpointNumber,'Quiz'].join('')))) == null) {
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('quiz', undefined);
              } else {
                await error2('Viktorīnas atbildes jau ir iesniegtas');
              }
            }
          } else {
            await error2('Ievadīta nepareiza atslēga!');
          }
        }
      }
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/participant/components/a74d69eb09975cbb54435ee8fd1546b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('points', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/points/components/0c088e5b73d11c551ab1a1c39e06ab82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/points/components/5148dca449660f7bd8a207640366d2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (pageName, pageData) { BackendlessUI.goToPage(pageName, pageData) })('participant', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/points/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var points, result, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!(((getObjectProperty(team, 'teamId')).startsWith('K')) || ((getObjectProperty(team, 'teamId')).startsWith('R')) || ((getObjectProperty(team, 'teamId')).startsWith('M')))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
    result = (await BackendlessUI.Functions.Custom['fn_bc01a48af416ec799ecfaa9af64b0313']((getObjectProperty(points, '0'))));
    ___arguments.context.pageData['pointsTotal'] = (getObjectProperty(result, 'pointsTotal'));
    ___arguments.context.pageData['results'] = result;
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/quiz/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var quiz, controlpointId, points, controlpointNumber, cpList, teamId, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!(((getObjectProperty(team, 'teamId')).startsWith('K')) || ((getObjectProperty(team, 'teamId')).startsWith('R')) || ((getObjectProperty(team, 'teamId')).startsWith('M')))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    teamId = (getObjectProperty(team, 'teamId'));
    points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId=\'',teamId,'\''].join(''))).setPageSize(1)));
    points = (getObjectProperty(points, '0'));
    if (!((getObjectProperty(points, 'cpToGo')) == null)) {
      cpList = (getObjectProperty(points, 'cpToGo')).split(',');
      controlpointNumber = 9 - cpList.length;
      controlpointId = cpList[0];
      if (!((getObjectProperty(points, (['cp',controlpointNumber,'Arival'].join('')))) == null)) {
        if ((getObjectProperty(points, (['cp',controlpointNumber,'Quiz'].join('')))) == null) {
          quiz = (await Backendless.Data.of('Quiz').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',controlpointId,'\''].join(''))).excludeProperties(['answer1Correct', 'answer2Correct', 'answer3Correct']).setPageSize(1)));
          quiz = (getObjectProperty(quiz, '0'));
          ___arguments.context.pageData['quiz'] = quiz;
          ___arguments.context.pageData['controlpointId'] = controlpointId;
        } else {
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('participant', undefined);
        }
      } else {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('participant', undefined);
      }
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('participant', undefined);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/quiz/components/d26aee61ba865be2e8c27c5427be9891/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, pointsSaved, controlpointNumber, points, team, quizPoints, answers, answer3, answer2, answer1, controlpointId, cpList;

/**
 * Describe this function...
 */
async function error2(error) {
  ___arguments.context.pageData['errorTxt'] = error;
  ___arguments.context.pageData['errorVisibility'] = true;
}

/**
 * Describe this function...
 */
async function notError() {
  ___arguments.context.pageData['errorVisibility'] = false;
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    answer1 = (getObjectProperty(___arguments.context.pageData, 'answer1'));
    answer2 = (getObjectProperty(___arguments.context.pageData, 'answer2'));
    answer3 = (getObjectProperty(___arguments.context.pageData, 'answer3'));
    if (!answer1 || !answer2 || !answer3) {
      await error2('Visām atbildēm jābūt atzīmētām');
    } else {
      await notError();
      team = (await Backendless.UserService.getCurrentUser(false));
      points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
      points = (getObjectProperty(points, '0'));
      if (!((getObjectProperty(points, 'cpToGo')) == null)) {
        controlpointId = (getObjectProperty(___arguments.context.pageData, 'controlpointId'));
        cpList = (getObjectProperty(points, 'cpAll')).split(',');
        controlpointNumber = cpList.indexOf(controlpointId) + 1;
        quizPoints = 0;
        answers = (await Backendless.Data.of('Quiz').find(Backendless.DataQueryBuilder.create().setWhereClause((['controlpointId = \'',controlpointId,'\''].join(''))).setProperties(['answer1Correct', 'answer2Correct', 'answer3Correct', 'points1', 'points2', 'points3']).setPageSize(1)));
        answers = (getObjectProperty(answers, '0'));
        if (answer1 == (getObjectProperty(answers, 'answer1Correct'))) {
          quizPoints = quizPoints + (Number((getObjectProperty(answers, 'points1'))));
        }
        if (answer2 == (getObjectProperty(answers, 'answer2Correct'))) {
          quizPoints = quizPoints + (Number((getObjectProperty(answers, 'points2'))));
        }
        if (answer3 == (getObjectProperty(answers, 'answer3Correct'))) {
          quizPoints = quizPoints + (Number((getObjectProperty(answers, 'points3'))));
        }
        try {
          points = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
          points = (getObjectProperty(points, '0'));
          points[(['cp',controlpointNumber,'Quiz'].join(''))] = quizPoints;
          await Backendless.Data.of('Points').save( points );
          pointsSaved = (await Backendless.Data.of('Points').find(Backendless.DataQueryBuilder.create().setWhereClause((['teamId = \'',(getObjectProperty(team, 'teamId')),'\''].join(''))).setPageSize(1)));
          pointsSaved = (getObjectProperty(pointsSaved, '0'));
          if ((getObjectProperty(points, (['cp',controlpointNumber,'Quiz'].join('')))) != (getObjectProperty(pointsSaved, (['cp',controlpointNumber,'Quiz'].join(''))))) {
            throw (new Error('Punkti netika saglabāt! Pārlādē lapu un mēģini vēlreiz'))
          }
          (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('points', undefined);

        } catch (error) {
          ___arguments.context.pageData['errorTxt'] = 'Neizdevās aprēķināt punktus! Pārlādē lapu un mēģini vēlreiz!';

        }
      } else {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('participant', undefined);
      }
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == '3064') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/quiz/components/9f0ec449c1762dbe26cb02d55834cbda/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'quiz.question1')),' (',(getObjectProperty(___arguments.context.pageData, 'quiz.points1')),' punkti)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/quiz/components/383c33ce6c0a1ddfa18fbd8eaf9fde46/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'quiz.question2')),' (',(getObjectProperty(___arguments.context.pageData, 'quiz.points2')),' punkti)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/quiz/components/0d07630af0bcb7886ec6848d04451091/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'quiz.question3')),' (',(getObjectProperty(___arguments.context.pageData, 'quiz.points3')),' punkti)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/results/components/8a4c7fac4da703613f8124d1b89e9a42/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      await (async function() {
  	let sortBtn = document.getElementsByClassName("MuiButtonBase-root")[4];
  	sortBtn.click();
  	setTimeout(() => {sortBtn.click();}, 200);
  })();

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/results/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var team, gameStatus;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else {
    gameStatus = (await Backendless.Data.of('Game').findFirst(Backendless.DataQueryBuilder.create().setProperties('gameStatus')));
    gameStatus = (getObjectProperty(gameStatus, 'gameStatus'));
    if (gameStatus != 'Finished') {
      await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/results/components/709942e1faa33ba77d757d77f25206e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/results/components/3414672979b18db2f257b181277ebb13/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('points', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/start/components/bb527e26d84340d13d99f68a4502119d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/start/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var gameStatus, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('S'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    gameStatus = (await Backendless.Data.of('Game').findFirst(Backendless.DataQueryBuilder.create().setProperties('gameStatus')));
    if ((getObjectProperty(gameStatus, 'gameStatus')) == 'Not Started') {
      ___arguments.context.pageData['startBtnVisibility'] = true;
    } else {
      ___arguments.context.pageData['startBtnVisibility'] = false;
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/start/components/3cb11f5fd9fac4314f74ff57a3234403/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['modalVisibility'] = true;
  ___arguments.context.pageData['startPopupVisibility'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/start/components/9d118379952f472813dd1e8a4d2c2dda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['modalVisibility'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/start/components/7e5b51cb25778dc3971ee91ffbaa6356/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var game;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  game = (await Backendless.Data.of('Game').find(Backendless.DataQueryBuilder.create().setPageSize(1)));
  game = (getObjectProperty(game, '0'));
  if ((getObjectProperty(game, 'gameStatus')) == 'Not Started') {
    try {
      game['gameStatus'] = 'Started';
      await Backendless.Data.of('Game').save( game );
      ___arguments.context.pageData['startPopupVisibility'] = false;
      ___arguments.context.pageData['successPopupVisibility'] = true;
      ___arguments.context.pageData['successMsg'] = 'Spēle sākusies!';
      ___arguments.context.pageData['successVisibility'] = 'Spēle sākusies!';

    } catch (error) {
      ___arguments.context.pageData['errorTxt'] = 'Kļūda iesākot spēli! Mēģini vēlreiz';
      ___arguments.context.pageData['errorVisibility'] = true;

    }
  } else {
    ___arguments.context.pageData['errorTxt'] = 'Spēle jau ir beigusies!';
    ___arguments.context.pageData['errorVisibility'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/statistics/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var startedDriversParticipantCount, totalDriversParticipantCount, startedDriversTeamCount, totalDriversTeamCount, startedCyclistsParticipantCount, totalCyclistsParticipantCount, startedCyclistsTeamCount, totalCyclistsTeamCount, startedWalkersParticipantCount, totalWalkersParticipantCount, startedWalkersTeamCount, totalWalkersTeamCount, startedParticipantCount, totalParticipantCount, startedTeamCount, totalTeamCount, team;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_0ed25ecc6faed036892dd73ab6918ddd']();
  team = (await Backendless.UserService.getCurrentUser(true));
  if (team == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  } else if (!((getObjectProperty(team, 'teamId')).startsWith('A'))) {
    await BackendlessUI.Functions.Custom['fn_a2695a499b038ccdb4fca1e8ba0b3e4f'](team);
  } else {
    totalWalkersTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'')));
    startedWalkersTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\' AND start = TRUE')));
    totalWalkersParticipantCount = totalWalkersTeamCount;
    totalWalkersParticipantCount = totalWalkersParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'')));
    totalWalkersParticipantCount = totalWalkersParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'')));
    totalWalkersParticipantCount = totalWalkersParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'')));
    totalWalkersParticipantCount = totalWalkersParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\'')));
    startedWalkersParticipantCount = startedWalkersTeamCount;
    startedWalkersParticipantCount = startedWalkersParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\' AND start = TRUE')));
    startedWalkersParticipantCount = startedWalkersParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\' AND start = TRUE')));
    startedWalkersParticipantCount = startedWalkersParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\' AND start = TRUE')));
    startedWalkersParticipantCount = startedWalkersParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'K%\' AND start = TRUE')));
    totalCyclistsTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'')));
    startedCyclistsTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\' AND start = TRUE')));
    totalCyclistsParticipantCount = totalCyclistsTeamCount;
    totalCyclistsParticipantCount = totalCyclistsParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'')));
    totalCyclistsParticipantCount = totalCyclistsParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'')));
    totalCyclistsParticipantCount = totalCyclistsParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'')));
    totalCyclistsParticipantCount = totalCyclistsParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\'')));
    startedCyclistsParticipantCount = startedCyclistsTeamCount;
    startedCyclistsParticipantCount = startedCyclistsParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\' AND start = TRUE')));
    startedCyclistsParticipantCount = startedCyclistsParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\' AND start = TRUE')));
    startedCyclistsParticipantCount = startedCyclistsParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\' AND start = TRUE')));
    startedCyclistsParticipantCount = startedCyclistsParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'R%\' AND start = TRUE')));
    totalDriversTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'')));
    startedDriversTeamCount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\' AND start = TRUE')));
    totalDriversParticipantCount = totalDriversTeamCount;
    totalDriversParticipantCount = totalDriversParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'')));
    totalDriversParticipantCount = totalDriversParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'')));
    totalDriversParticipantCount = totalDriversParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'')));
    totalDriversParticipantCount = totalDriversParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\'')));
    startedDriversParticipantCount = startedDriversTeamCount;
    startedDriversParticipantCount = startedDriversParticipantCount + (await Backendless.Data.of('SecondParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\' AND start = TRUE')));
    startedDriversParticipantCount = startedDriversParticipantCount + (await Backendless.Data.of('ThirdParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\' AND start = TRUE')));
    startedDriversParticipantCount = startedDriversParticipantCount + (await Backendless.Data.of('ForthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\' AND start = TRUE')));
    startedDriversParticipantCount = startedDriversParticipantCount + (await Backendless.Data.of('FifthParticipant').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('teamId LIKE \'M%\' AND start = TRUE')));
    totalTeamCount = totalWalkersTeamCount + totalCyclistsTeamCount + totalDriversTeamCount;
    totalParticipantCount = totalWalkersParticipantCount + totalCyclistsParticipantCount + totalDriversParticipantCount;
    startedTeamCount = startedWalkersTeamCount + startedCyclistsTeamCount + startedDriversTeamCount;
    startedParticipantCount = startedWalkersParticipantCount + startedCyclistsParticipantCount + startedDriversParticipantCount;
    ___arguments.context.pageData['count'] = ({ 'totalTeamCount': totalTeamCount,'startedTeamCount': startedTeamCount,'totalParticipantCount': totalParticipantCount,'startedParticipantCount': startedParticipantCount,'totalWalkersTeamCount': totalWalkersTeamCount,'startedWalkersTeamCount': startedWalkersTeamCount,'totalWalkersParticipantCount': totalWalkersParticipantCount,'startedWalkersParticipantCount': startedWalkersParticipantCount,'totalCyclistsTeamCount': totalCyclistsTeamCount,'startedCyclistsTeamCount': startedCyclistsTeamCount,'totalCyclistsParticipantCount': totalCyclistsParticipantCount,'startedCyclistsParticipantCount': startedCyclistsParticipantCount,'totalDriversTeamCount': totalDriversTeamCount,'startedDriversTeamCount': startedDriversTeamCount,'totalDriversParticipantCount': totalDriversParticipantCount,'startedDriversParticipantCount': startedDriversParticipantCount });
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/statistics/components/1d234c66e305d809129575d4bfba65f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await BackendlessUI.Functions.Custom['fn_f80223fbfb1deafaeefad6939f65270e']();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/statistics/components/52ea22317e585a6405625043a727f3ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (pageName, pageData) { BackendlessUI.goToPage(pageName, pageData) })('admin', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0349d45cb33c14dc6b598fccd3cca239/logic/e2ea1ae9826a5596a2d11d7bc8b1b53f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('info', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0a6366e835c5b894f04af20440db75bd/logic/6a9fdad6cd557c7752d67f2f8c47040e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['modalVisibility'] = false;

  },
  /* handler:onClick */
  /* content */
}))

